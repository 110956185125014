.favorite-container {
    position: fixed;
    top: 3.5%;
    right: 2px;
    background-color: #fff;
    z-index: 9999;
    border: 1px solid #000;
    width: auto;
    height: auto;
    padding: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .favorite-header-icon{
        cursor: pointer;
        margin-left: auto;
    }

    .favorite-header{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        flex-direction: column;

        .favorite-header-text{
            margin-top: 5px;
            text-align: center;
        }
    }

    .product-list{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
        max-height: 70vh;
        overflow-y: auto;
        padding: 20px 15px;
    }

    .share-button{
        margin-top: 20px;
        display: block;
        padding: 10px;
        width: 200px;
        background-color: #000;
        color: #fff;
        border: none;
        cursor: pointer;
        font-size: 15px;
        text-transform: uppercase;
    }
}

  /* Overlay background */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

@media (max-width: 767px) {
    .favorite-container {
      width: 100vw;
        height: 97vh;
        right: 0;
        padding: 20px; 
        border: none;

        .product-list{
          grid-template-columns: repeat(2, 1fr);
          gap: 20px;
          padding: 0 30px;
        }
    }
  }