
.pim-viewer{

  .slider-with-large-image-container {
      display: flex;
      height: 100vh;
      width: 100vw;
      max-width: 857px;
      max-height: 798px;
      gap: 5px;
      box-sizing: border-box;

      .large-image-container {
          width: 638px;
          height: 798px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #f0f0f0;
      }
  
      .large-image-container img {
          width: 100%;
          height: auto;
          max-height: 100%;
          object-fit: cover;
      }

  }

  .product-details-container {
      //width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
  }

  /* Media Queries for Responsive Design */
  @media (max-width: 1200px) {
      .slider-with-large-image-container {
        max-width: 100%;
        max-height: calc(100vh - 20px);
      }
    }

    @media (max-width: 768px) {
      .vertical-slider-container {
        width: 30%;
        min-width: 150px;
      }

      .large-image-container {
        padding: 0 5px;
      }
    }

    @media (max-width: 480px) {
      .vertical-slider-container {
        width: 40%;
        min-width: 120px;
      }
    }
}

@media (max-width: 991px) { /* Tablets and smaller */
  .pim-viewer .row {
    flex-direction: column;
    align-items: center;
  }

  .pim-viewer .col-md-12,
  .pim-viewer .col-sm-12 {
    max-width: 100%;
  }
}
