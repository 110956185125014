.product-details {
    display: flex;
    height: auto;
    flex-direction: column;
    gap: 8px;
    width: 100%;

    .product-material {
        font-size: 15px;
        color: #888;
        text-align: left;
        margin: 3px;
        padding: 0;
        font-family: 'Mont-Regular', sans-serif;
    }

    .title-price-favorite-row {
        display: flex;
        align-items: center;
        text-align: left;
        gap: 8px;
        width: 100%;

        .product-title {
            font-size: 24px;
            font-weight: bold;
            margin: 0;
            font-family: 'Safira March', sans-serif;
        }

        .product-price {
            font-size: 20px;
            color: #333;
            margin: 0;
            margin-left: auto;
            font-family: 'Mont-Regular', sans-serif;
        }

        .favorite-button {
            background: none;
            border: none;
            cursor: pointer;
            padding: 0;
            margin-left: 8px;
        }

        .favorite-icon {
            font-size: 24px;
            color: #888;
        }
    }

    .product-in-stock-container {
        display: flex;
        align-items: center;
        font-family: 'Mont-Regular', sans-serif;

        .green-in-stock-icon {
            color: #00b300;
            font-size: 10px;
            filter: drop-shadow(0 0 4px rgba(0, 179, 0, 0.5));
        }

        .yellow-in-stock-icon {
            color: #FFF701;
            font-size: 10px;
            filter: drop-shadow(0 0 4px rgba(225, 247, 1, 0.5));
        }

        .red-in-stock-icon {
            color: #D73434;
            font-size: 10px;
            filter: drop-shadow(0 0 4px rgba(215, 52, 52, 0.5));

        }

        .in-stock-text {
            text-align: center;
            margin: 0 10px;
            font-size: 12px;
            color: #888;
        }
    }

    .favorite-icon.filled {
        color: rgb(0, 0, 0);
    }

    .color-section {
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
        font-family: 'Mont-Regular', sans-serif;

        .product-color {
            font-size: 15px;
            margin: 0;
            text-align: left;
            margin-bottom: 5px;
        }

        .color-container {
            display: flex;
            gap: 5px;
            margin-left: 7px;

            .color-circle {
                width: 24px;
                height: 24px;
                border-radius: 50%;
                border: 1px solid #ccc;
            }

            .color-circle-split {
                width: 24px;
                height: 24px;
                border-radius: 50%;
                display: inline-block;
            }
        }
    }

    .size-section {
        margin-top: 11px;
        font-family: 'Mont-Regular', sans-serif;

        .size-title {
            font-size: 15px;
            margin: 0;
            margin-bottom: 10px;
            text-align: left;
        }

        .size-container {
            display: flex;
            gap: 8px;

            .size-box {
                background-color: #F4F4F4;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 3px 7px;
                font-size: 9px;
                text-align: center;
                width: auto;
                font-size: 13px;
            }

            .selected {
                background-color: black;
                color: white;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 3px 7px;
                font-size: 9px;
                text-align: center;
                width: auto;
                font-size: 13px;
            }
        }
    }

    .button-container {
        display: flex;
        justify-content: center;
        margin-top: 36px;

        .quantity-input {
            width: 65px;
            padding: 5px;
            font-size: 16px;
            text-align: center;
            border: 1px solid #ccc;
            margin: 0 7px;
        }

        .add-to-cart-button {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #000000;
            color: #fff;
            border: none;
            width: 251px;
            height: 39px;
            font-size: 15px;
            text-transform: uppercase;
            cursor: pointer;
            font-family: 'Mont-Regular', sans-serif;

            .gem-icon {
                margin-left: 12px;
                font-size: 20px;
            }
        }

        .add-to-cart-button:hover {
            background-color: rgba(32, 32, 32, 0.9);
        }
    }
}

@media (max-width: 991px) {
    .product-details {
        padding: 0 15px;
    }
}