.dropdown-container {
    display: flex;
    background-color: black;
    color: white;
    padding-bottom: 20px;
    font-family: 'Safira March Font', sans-serif;

    @media (max-width: 1070px) {
        height: 100vh;
    }

    .left-menu-block {
        width: 290px;
        margin-right: 50px;
        margin-left: 100px;
        display: flex;
        flex-direction: column;
        gap: 6px;

        div {
            a {

                text-decoration: none;
                color: white;
                transition: color 0.3s;
                font-size: 13px;
                text-align: left;
                margin-bottom: 6px;

                &:hover {
                    color: #4b4848;
                }
            }
        }

        .menu-text:first-child {
            a {
                font-size: 30px;
            }
        }

        .menu-text {
            a {
                font-size: 20px;
            }
        }
    }

    .left-menu-block a {
        display: block;
    }

    /* Mobile & Tablet: Center Everything */
    @media (max-width: 1070px) {
        
        .left-menu-block {
            width: 100%;
            margin: 0;
            align-items: center;
            //height: 100vh;
            max-height: 70vh;
            //min-height: 100vh;
            overflow-y: auto;

            .menu-text {
                display: flex;
                flex-direction: column;
                align-items: center;

                a {
                    text-align: center;
                    font-size: 13px;
                }
            }

            .menu-text:first-child {
                a {
                    font-size: 23px;
                }
            }

            .menu-text {
                a {
                    font-size: 18px;
                }
            }
        }

        .mobile-active-template-container {
            display: flex;
            justify-content: center;
            width: 100%;
            white-space: nowrap;
            padding: 0 7px;
        }

        .mobile-active-template {
            display: inline-block;
            max-width: 100%;
        }
    
    }
}